
























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Error extends Vue {
  @Prop({default: 200})
  private status!: number;

  @Prop({default: ''})
  private beforePath!: string;

  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }
}
